import { Helmet } from 'react-helmet-async';
import React from 'react';

import server from 'server';
import i18n from 'i18';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
    Box,
    CircularProgress
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// components
import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar';

// sections
import { GridListHead, GridListToolbar } from 'sections/grid';

import DialogAbstract from './DialogAbstract';
import Cells from './cells';
import { Navigate } from 'react-router-dom';
import { formatError } from 'utils/formatError';
import helper from 'utils/helper';
import ConfirmDialog from './dialogs/ConfirmDialog';
import config from '../config';
// lodash
import { debounce } from 'lodash';
import { formatValue } from '../utils/formatName';

export default class GridPageAbstract extends React.Component {
    List = [];
    Head = [];
    Name = 'Grid';
    Server = null;
    searchFields = [];
    exportButton = false;
    MenuDisplayType = 'menu' || 'none';
    CheckboxCell = true;
    //change to use permissions
    AddButton = true;

    CanDelete = ['admin', 'therapist', 'reseller', 'user'];
    CanUpdate = ['admin', 'therapist', 'reseller', 'user'];
    CanAdd = ['admin', 'therapist', 'reseller', 'user'];

    handleExportDataToCsv = () => {};

    constructor(props) {
        super(props);

        this.state = {
            open: null,
            cache: null,
            page: 0,
            rowsPerPage: 25,
            totalCount: 0,
            sortConfig: config.defaultSort,
            selected: [],
            searchValue: '',
            menuClickItemId: null,
            alertText: null,
            snackbarOpen: false,
            isLoading: true,
            filterRequest: [],

            dialogOpen: false,
            dialogData: {},

            navigateLink: false,

            confirmDialogOpen: false,
            confirmationDeleteText: ''
        };

        this.debouncedSearch = debounce(this.retrieveData, 500);
    }

    allowedPermission(permissions) {
        let role = JSON.parse(localStorage.user).role;
        return permissions.indexOf(role) >= 0;
    }

    async updatePermissionsData() {
        const data = await server.users.metaInfo();
        this.props.context.update({ permissions: data.permissions });
    }

    async retrieveData() {
        this.setState({ isLoading: true });
        try {
            const sort = this.Server.getSortField(
                this.state.sortConfig.field,
                this.state.sortConfig.direction
            );

            let result = await this.Server.getAllFiltered(
                this.state.filterRequest,
                sort,
                {
                    paging: {
                        page: this.state.page,
                        pageSize: this.state.rowsPerPage
                    }
                }
            );
            this.List = result.data || [];

            if (this.state.page === 0) {
                this.setState({ totalCount: result.total_count });
            }

            let cache = {};
            for (let j in this.Head) {
                const field = this.Head[j];

                if (field.from && !cache[field.from]) {
                    result = await server[field.from].getAll();
                    // TODO receive ready select items from the server instead of full objects
                    cache[field.from] = result.data.map((i) => {
                        return {
                            id: i.id,
                            value: formatValue(i, field.display),
                            ...(i.role && { role: i.role })
                        };
                    });
                }
            }

            this.setState({ cache: cache });
        } catch (error) {
            console.log('error', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    async componentDidMount() {
        await this.retrieveData();
    }

    handleOpenMenu(event, id) {
        this.setState({ open: event.currentTarget, menuClickItemId: id });
    }

    handleCloseMenu() {
        this.setState({ open: null, menuClickItemId: null });
    }

    handleFilterItems = async (filterReq) => {
        this.setState({ filterRequest: [filterReq], page: 0 }, () =>
            this.retrieveData()
        );
    };

    handleSearch = (value) => {
        if (value === '') {
            this.setState({ filterRequest: [], searchValue: '' }, () =>
                this.retrieveData()
            );
            return;
        }
        const searchFilters = this.searchFields.map((field) => {
            return [{ field: field, op: ':', value: value }];
        });
        this.setState({ page: 0 });
        this.setState({ filterRequest: searchFilters, searchValue: value });
        this.debouncedSearch();
    };

    handleRequestSort = (sortField) => {
        const { field, direction } = this.state.sortConfig;
        const isAsc = field === sortField && direction === 'asc';
        this.setState(
            {
                sortConfig: {
                    field: sortField,
                    direction: isAsc ? 'desc' : 'asc'
                }
            },
            () => this.retrieveData()
        );
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.List.map((n) => n.name);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, name) => {
        const selectedIndex = this.state.selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1)
            );
        }
        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage }, () => this.retrieveData());
    };

    handleChangeRowsPerPage = (event) => {
        this.setState(
            { page: 0, rowsPerPage: parseInt(event.target.value, 10) },
            () => this.retrieveData()
        );
    };

    handleEditItem = (event) => {
        this.dialogOpen(
            this.List.filter(
                (item) => item.id === this.state.menuClickItemId
            ).at(0)
        );
    };

    handleDeleteItem = async (event) => {
        // single delete from right click menu
        // or multi deleted from selected by checkbox items
        let result = await this.Server.delete(
            this.state.menuClickItemId
                ? [this.state.menuClickItemId]
                : this.state.selected
        );
        this.setState({ menuClickItemId: null, open: false });
        if (result.error !== 'OK') {
            this.setState({
                snackbarOpen: true,
                alertText: formatError(result),
                selected: []
            });
        } else {
            this.setState({
                filterRequest: [],
                selected: [],
                confirmDialogOpen: false
            });
            await this.retrieveData();
        }
    };

    handleSingleDeleteConfirmDialogOpen = (event) => {
        this.setState({
            confirmDialogOpen: true,
            confirmationDeleteText: i18n.t(
                `dialogs.${this.Name}ConfirmationSingleDelete`
            )
        });
    };

    // getValueFromRow(row, field) {
    //     const accessStrategies = {
    //         object: (r, f) => r[f.object],
    //         parent: (r, f) => r[f.parent]?.[f.id] ?? null,
    //         default: (r, f) => r[f.id]
    //     };
    //     for (const key in accessStrategies) {
    //         if (field[key]) {
    //             return accessStrategies[key](row, field);
    //         }
    //     }
    //     return accessStrategies.default(row, field);
    // }

    renderGridSingleRow(row) {
        const { id } = row;
        const selectedUser = this.state.selected.indexOf(id) !== -1;

        return (
            <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={selectedUser}
            >
                {this.CheckboxCell && (
                    <TableCell key="checkbox" padding="checkbox">
                        <Checkbox
                            checked={selectedUser}
                            onChange={(event) => this.handleClick(event, id)}
                        />
                    </TableCell>
                )}

                {this.Head.map((field) => {
                    let Cell = Cells[field.cell];
                    if (!Cell) Cell = Cells.Text; // fallback to text cell
                    let value = field.object
                        ? row[field.object]
                        : row[field.id];
                    return (
                        <Cell
                            key={field.id}
                            id={row.id}
                            row={row}
                            value={value}
                            field={field}
                            handleChange={this.handleChangeCell}
                            handleSave={this.handleSaveCell}
                            currentValues={this.state.currentValues}
                        />
                    );
                })}
                {this.MenuDisplayType === 'none' ? (
                    <></>
                ) : (
                    <TableCell key="menu" align="right">
                        <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => this.handleOpenMenu(event, id)}
                        >
                            <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
        );
    }

    renderGridBodyRows() {
        return (
            <>
                {this.List.map((row) => {
                    return this.renderGridSingleRow(row);
                })}
            </>
        );
    }

    renderGridBodyEmptyRows() {
        const emptyRows =
            this.state.page > 0
                ? Math.max(
                    0,
                    (1 + this.state.page) * this.state.rowsPerPage -
                          this.state.totalCount
                )
                : 0;
        return (
            <>
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </>
        );
    }

    renderGridBodyNotFound() {
        const emptyCol = this.Head.length + 2;
        return (
            <>
                <TableBody>
                    <TableRow>
                        <TableCell
                            align="center"
                            colSpan={emptyCol}
                            sx={{ py: 3 }}
                        >
                            <Paper
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="h6" paragraph>
                                    Not found
                                </Typography>

                                <Typography variant="body2">
                                    No results found for &nbsp;
                                    <strong>
                                        &quot;{this.state.searchValue}&quot;
                                    </strong>
                                    .
                                    <br /> Try checking for typos or using
                                    complete words.
                                </Typography>
                            </Paper>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </>
        );
    }

    renderCustomMenuItems() {
        return <></>;
    }

    renderMenuPopover() {
        return (
            <>
                <Popover
                    open={Boolean(this.state.open)}
                    anchorEl={this.state.open}
                    onClose={(event) => this.handleCloseMenu(event)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            "p": 1,
                            "width": 200,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75
                            }
                        }
                    }}
                >
                    {this.allowedPermission(this.CanUpdate) && (
                        <MenuItem
                            onClick={(event) => this.handleEditItem(event)}
                        >
                            <EditIcon sx={{ mr: 2 }} />
                            {i18n.t('buttons.edit')}
                        </MenuItem>
                    )}
                    {this.renderCustomMenuItems()}
                    {this.allowedPermission(this.CanDelete) && (
                        <MenuItem
                            onClick={(event) =>
                                this.handleSingleDeleteConfirmDialogOpen()
                            }
                            sx={{ color: 'error.main' }}
                        >
                            <DeleteForeverIcon sx={{ mr: 2 }} />
                            {i18n.t('buttons.delete')}
                        </MenuItem>
                    )}
                </Popover>
            </>
        );
    }

    renderCustom() {
        return <></>;
    }

    render() {
        if (!this.state.cache) {
            return <></>;
        }

        const userAgent =
            typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                userAgent
            );
        const isNotFound = !this.List.length;

        return (
            <>
                {this.state.navigateLink && (
                    <Navigate to={this.state.navigateLink} />
                )}

                {this.renderCustom()}

                {this.renderDialog()}

                {this.renderConfirmDialog()}

                <Helmet>
                    <title>
                        {helper.formPageTitle(`pages.${this.Name}.title`)}
                    </title>
                </Helmet>

                <Container maxWidth={false}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={5}
                    >
                        <Typography variant="h4" gutterBottom>
                            {i18n.t(`pages.${this.Name}.title`)}
                        </Typography>
                        {this.allowedPermission(this.CanAdd) &&
                            this.AddButton && (
                            <Button
                                variant="contained"
                                onClick={(event) => this.dialogOpen()}
                                startIcon={<Iconify icon="eva:plus-fill" />}
                            >
                                {i18n.t(`pages.${this.Name}.add`)}
                            </Button>
                        )}
                    </Stack>

                    <Card>
                        <GridListToolbar
                            Head={this.Head}
                            data={this.List}
                            name={this.Name}
                            exportButton={this.exportButton}
                            exportDataToCsv={() =>
                                this.handleExportDataToCsv.bind(this)()
                            }
                            numSelected={this.state.selected.length}
                            searchValue={this.state.searchValue}
                            handleSearchData={this.handleSearch}
                            handleFilterItems={(filterReq) =>
                                this.handleFilterItems(filterReq)
                            }
                            onDelete={() =>
                                this.setState({
                                    confirmDialogOpen: true,
                                    confirmationDeleteText: i18n.t(
                                        `dialogs.${this.Name}ConfirmationMultipleDelete`
                                    )
                                })
                            }
                        />
                        <Scrollbar
                            sx={
                                isMobile
                                    ? {
                                        overflowY: 'auto',
                                        maxHeight: 400
                                    }
                                    : {
                                        minWidth: 800,
                                        overflowY: 'auto',
                                        maxHeight: 450
                                    }
                            }
                        >
                            {this.state.isLoading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(255, 255, 255, 0.7)',
                                        zIndex: 2
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <TableContainer
                                sx={{ overflowX: 'initial', minHeight: 53 * 5 }}
                            >
                                <Table stickyHeader>
                                    <GridListHead
                                        order={this.state.sortConfig.direction}
                                        orderBy={this.state.sortConfig.field}
                                        headLabel={this.Head}
                                        rowCount={this.List.length}
                                        numSelected={this.state.selected.length}
                                        onRequestSort={this.handleRequestSort}
                                        onSelectAllClick={
                                            this.handleSelectAllClick
                                        }
                                        checkboxCell={this.CheckboxCell}
                                    />
                                    <TableBody>
                                        {this.renderGridBodyRows(this.List)}
                                        {this.renderGridBodyEmptyRows()}
                                    </TableBody>

                                    {isNotFound &&
                                        this.renderGridBodyNotFound()}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.totalCount}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            labelRowsPerPage={i18n.t('text.rowsPerPage')}
                            labelDisplayedRows={helper.labelDisplayedRows}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>

                {this.renderMenuPopover()}

                <Snackbar
                    open={this.state.snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => this.handleSnackbarClose()}
                >
                    <Alert
                        onClose={() => this.handleSnackbarClose()}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        {this.state.alertText}
                    </Alert>
                </Snackbar>
            </>
        );
    }

    handleSnackbarClose() {
        this.setState({ snackbarOpen: false });
    }

    dialogOpen(data) {
        if (data) {
            // if we have select objects, then extracting id value into data for editor
            let dialogFields = this.Head.filter((f) => !f.disableEditor);
            let dialogData = { id: data.id };
            dialogFields.forEach((f) => {
                if (f.object) {
                    let value = data[f.object];
                    dialogData[f.id] = value ? value.id : null;
                } else {
                    dialogData[f.id] = data[f.id];
                }
            });

            this.setState({ dialogOpen: true, dialogData });
        } else {
            this.setState({ dialogOpen: true, dialogData: {} });
        }
    }

    async handleDialogOnSave(data) {
        let getDataResult = await this.Server.getAll();
        this.List = getDataResult.data;

        this.forceUpdate();
        this.setState({ dialogOpen: false, dialogData: null });
    }

    async handleDialogOnClose() {
        this.setState({ dialogOpen: false, dialogData: null });
    }

    renderConfirmDialog() {
        return (
            <ConfirmDialog
                dialogOpen={this.state.confirmDialogOpen}
                onConfirm={() => this.handleDeleteItem()}
                onCancel={() =>
                    this.setState({
                        confirmDialogOpen: false,
                        confirmationDeleteText: ''
                    })
                }
                alertText={this.state.confirmationDeleteText}
                alertTitle={i18n.t('dialogs.confirmationTitle')}
            />
        );
    }

    renderDialog() {
        return (
            <DialogAbstract
                dialogOpen={this.state.dialogOpen}
                dialogData={this.state.dialogData}
                cache={this.state.cache}
                Name={this.Name}
                Head={this.Head}
                Server={this.Server}
                onSave={(data) => this.handleDialogOnSave(data)}
                onClose={() => this.handleDialogOnClose()}
            />
        );
    }
}
